import { Text } from '@chakra-ui/react'
import React from 'react'

const Dot = ({ ...rest }) => (
  <Text as="span" color="inherit" opacity={0.6} {...rest}>
    •
  </Text>
)

export default Dot
