import { Flex, HStack, Text } from '@chakra-ui/react'

const Service = ({ children }: Props) => {
  if (!children) return <></>
  if (!children.length) return <></>
  return (
    <Flex gap={2} flexWrap="wrap">
      {children.map((child, index) => (
        <Text as="span" fontSize={['sm', 'md']} key={child.uid}>
          {child.title}
          {index !== children.length - 1 && ','}
        </Text>
      ))}
    </Flex>
  )
}

type Props = {
  children: any
  fontWeight?: string
  colorTheme?: string
  marginB?: number
}

export default Service
