import { Text, Stack, TextProps, Flex, Box } from '@chakra-ui/react'
import React from 'react'
import { Campus as CampusType } from 'types'
import Dot from './Dot'

const Campus = ({ children, campus, dot = true, ...rest }: Props) => {
  if (!campus) return null
  if (!campus.length) return null
  return (
    <Flex gap={1} flexWrap="wrap">
      {campus.map((child, index) => (
        <Flex key={child.uid} gap={1}>
          <Text fontSize={['sm', 'md']} {...rest}>
            {child.title}
          </Text>
          {index !== campus.length - 1 && <Dot />}
        </Flex>
      ))}
    </Flex>
  )
}

type Props = {
  campus: CampusType[] | [CampusType]
  dot?: boolean
} & TextProps

export default Campus
