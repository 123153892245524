import { Button } from '@chakra-ui/react'
import useUser from '../hooks/useUser'
import { useRouter } from 'next/router'
import React from 'react'
import { AiFillStar } from 'react-icons/ai'
import { FiStar } from 'react-icons/fi'
import useSWR, { mutate } from 'swr'
import { Campus as CampusType, User } from 'types'
import { useFetchWithToken } from '../utils/backendDataFetcher'
import constants from '../utils/constants'
import l10n from '../l10n'

const ADD_FAVORITE = `
  mutation addOrUpdateFavorite($input: AddOrUpdateFavoriteInput!) {
    addOrUpdateFavorite(input: $input) {
      id
      name
      type
      uid
      slug
    }
  }
`

const DELETE_FAVORITE = `
  mutation deleteFavorite($id: ID!) {
    deleteFavorite(id: $id) {
      id
    }
  }
`

const GET_FAVORITES = `
  query favorites {
    favorites {
      id
      type
      name
      uid
      slug
    }
  }
`

const AddAsFavorite = ({ entry, type }: { entry: any; type?: any }) => {
  const router = useRouter()
  const { fetcher } = useFetchWithToken()
  const { data: user } = useUser()
  const { data, error } = useSWR(GET_FAVORITES)

  const handleDelete = async (favorite) => {
    try {
      const updated = data.favorites.filter((i) => i.id !== favorite.id)
      mutate(GET_FAVORITES, { favorites: [...updated] }, false)
      await mutate([GET_FAVORITES, entry.id], async () => {
        return await fetcher.request(DELETE_FAVORITE, {
          id: favorite.id,
        })
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleAdd = async () => {
    try {
      const input = {
        id: favorite ? favorite.id : null,
        type: constants.FavoriteTypes.Restaurants,
        name: entry.title,
        uid: entry.uid,
        slug: entry.slug,
      }
      // Optimistically mutate the local state.
      mutate(GET_FAVORITES, { favorites: [...data.favorites, input] }, false)
      // Mutate the individual entry's local state and
      // request the server to do the same.
      await mutate([GET_FAVORITES, entry.id], async () => {
        return await fetcher.request(ADD_FAVORITE, {
          input,
        })
      })
    } catch (error) {
      console.error(error)
    }
  }

  // Currently we only allow eateries as favorites, bail out.
  if (type !== constants.EntryTypes.Eatery) return <></>
  if (error) return <></>

  if (!data && !user)
    return (
      <Button
        size={'xs'}
        colorScheme={'gray'}
        leftIcon={<FiStar />}
        onClick={() => router.push('/minside/logginn', '/minside/logginn')}
      >
        {l10n.save}
      </Button>
    )

  const favorite = data?.favorites?.find((f) => f.uid === entry.uid)

  if (user && favorite) {
    return (
      <Button size={'xs'} leftIcon={<AiFillStar />} onClick={() => handleDelete(favorite)}>
        {l10n.remove}
      </Button>
    )
  }
  if (user) {
    return (
      <Button size={'xs'} leftIcon={<FiStar />} colorScheme={'gray'} onClick={handleAdd}>
        {l10n.save}
      </Button>
    )
  }
  return <></>
}

type Response = {
  isValidating?: any
  error?: any
  data?: {
    user: User
    favorites: any
    categories: [CampusType]
  }
}

export default AddAsFavorite
