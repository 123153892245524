import { Box, Flex, LinkBox, LinkOverlay, Text } from '@chakra-ui/react'
import l10n from '../l10n'
import RouterLink from 'next/link'
import { FiCreditCard, FiUserX } from 'react-icons/fi'
import { Campus as CampusType, EventEntry, MainImage, Service as ServiceType } from 'types'
import constants from '../utils/constants'
import AddAsFavorite from './AddAsFavorite'
import Campus from './Campus'
import ChakraNextImage from './ChakraNextImage'
import Date from './Date'
import GridItem from './GridItem'
import Service from './Service'

const EateryCard = ({ entry }: Props) => (
  <GridItem colStart={null} colSpan={[6, 6, 4, 3]} height="100%">
    <LinkBox
      as="article"
      _hover={{ a: { textDecoration: 'underline' } }}
      borderRadius={'xl'}
      height="100%"
      // backgroundColor="gray.100"
      border={'1px solid'}
      borderColor={'gray.300'}
    >
      <Box position="relative">
        <Box m={2} right={0} zIndex={66} position="absolute">
          <AddAsFavorite entry={entry} type={constants.EntryTypes.Eatery} />
        </Box>
      </Box>
      {entry.mainImage?.[0] ? (
        <Box position={'relative'} height={['110px', '230px']}>
          <ChakraNextImage
            // @ts-ignore
            fill
            objectFit={'cover'}
            objectPosition={`${entry.mainImage?.[0]?.focalPoint?.[0] * 100}% ${
              entry.mainImage?.[0]?.focalPoint?.[1] * 100
            }%`}
            src={entry.mainImage[0]?.path}
            alt={entry.mainImage[0]?.title || ''}
            placeholder={entry?.mainImage?.[0]?.imageOptimize?.placeholderImage ? 'blur' : 'empty'}
            blurDataURL={entry?.mainImage?.[0]?.imageOptimize?.placeholderImage || ''}
            sizes="(min-width: 30em) 25vw, 50vw"
            borderTopRightRadius={'xl'}
            borderTopLeftRadius={'xl'}
          />
        </Box>
      ) : (
        <Box
          height={['110px', '230px']}
          bgColor={'gray.200'}
          borderTopRightRadius={'xl'}
          borderTopLeftRadius={'xl'}
        />
      )}
      <Flex flexDirection={'column'} gap={1} p={[2, 4]}>
        <Campus campus={entry.campus}></Campus>
        <RouterLink href={entry?.uri} passHref legacyBehavior>
          <LinkOverlay fontWeight={'medium'} fontSize={['md', 'xl']} noOfLines={1}>
            {entry?.title}
          </LinkOverlay>
        </RouterLink>
        <Text noOfLines={3} fontSize={['sm', 'md']}>
          {entry.intro}
        </Text>
        <Box mt={[2, 3]}>
          <Text fontSize={['xs', 'sm']} color="gray.500">
            Åpningstider
          </Text>
          {entry.openingHours?.map((row, index) => (
            <Text key={`${row.day}-${index}`} fontSize={['xs', 'sm']}>
              {row.day}:
              <Text as="span" fontSize={['xs', 'sm']} ml={1}>
                {row.hours}
              </Text>
            </Text>
          ))}
        </Box>
        {entry.features && (
          <Box mt={3}>
            {entry.features?.map((feature) => {
              if (feature === constants.EateryFeatures.Unmanned) {
                return (
                  <div key={feature}>
                    <Box
                      display="inline"
                      color="gray.500"
                      width={3}
                      height={3}
                      mr={1}
                      as={FiUserX}
                    />
                    <Text mr={3} display="inline" fontSize="xs" color="gray.700">
                      {l10n.unmanned}
                    </Text>
                  </div>
                )
              } else if (feature === constants.EateryFeatures.CardOnly) {
                return (
                  <div key={feature}>
                    <Box
                      display="inline"
                      color="gray.500"
                      width={3}
                      height={3}
                      mr={1}
                      as={FiCreditCard}
                    />
                    <Text mr={3} display="inline" fontSize="xs" color="gray.700">
                      {l10n.cardOnly}
                    </Text>
                  </div>
                )
              }
            })}
          </Box>
        )}
      </Flex>
    </LinkBox>
  </GridItem>
)

const ListCard = ({ entry }: Props) => {
  if (entry.typeHandle === constants.EntryTypes.Eatery) {
    return <EateryCard entry={entry} />
  }

  return (
    <GridItem colStart={1} colSpan={[12, 6, 4, 3]} height="100%">
      <LinkBox
        as="article"
        _hover={{ a: { textDecoration: 'underline' } }}
        borderRadius={'xl'}
        height="100%"
        border={'1px solid'}
        borderColor={'gray.300'}
        // backgroundColor="gray.100"
      >
        {entry.mainImage?.[0] ? (
          <Box position={'relative'} height={['200px']}>
            <ChakraNextImage
              // @ts-ignore
              fill
              objectFit={'cover'}
              objectPosition={`${entry.mainImage?.[0]?.focalPoint?.[0] * 100}% ${
                entry.mainImage?.[0]?.focalPoint?.[1] * 100
              }%`}
              src={entry.mainImage[0]?.path}
              alt={entry.mainImage[0]?.title || ''}
              placeholder={
                entry?.mainImage?.[0]?.imageOptimize?.placeholderImage ? 'blur' : 'empty'
              }
              blurDataURL={entry?.mainImage?.[0]?.imageOptimize?.placeholderImage || ''}
              sizes="(min-width: 30em) 25vw, 50vw"
              borderTopRightRadius={'xl'}
              borderTopLeftRadius={'xl'}
            />
          </Box>
        ) : (
          <Box
            height={['200px']}
            bgColor={'gray.200'}
            borderTopRightRadius={'xl'}
            borderTopLeftRadius={'xl'}
          />
        )}
        <Flex flexDirection={'column'} gap={1} p={[2, 4]}>
          <Campus campus={entry.campus}></Campus>
          <RouterLink href={entry?.uri} passHref legacyBehavior>
            <LinkOverlay fontWeight={'medium'} fontSize={['md', 'xl']} noOfLines={2}>
              {entry?.title}
            </LinkOverlay>
          </RouterLink>
          <Text noOfLines={3} fontSize={['sm', 'md']}>
            {entry.intro}
          </Text>
          {entry.typeHandle === constants.EntryTypes.Event && (
            <Text>
              Tidspunkt: <Date withTime>{entry.date}</Date>
            </Text>
          )}
          <Service fontWeight="normal">{entry.service}</Service>
        </Flex>
      </LinkBox>
    </GridItem>
  )
}

type Props = {
  entry?: {
    uid: string
    title: string
    intro: string
    postDate: string
    date?: string
    url: string
    uri: string
    slug: string
    features?: []
    openingHours: [
      {
        day: string
        hours: string
      }
    ]
    mainImage: MainImage
    campus: [CampusType]
    service: [ServiceType]
    typeHandle: string
  }
}

export default ListCard
